<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'
import axios from "axios";

import ContactMessage from "@components/ContactMessage";
import GoogleReCaptchaV3 from "@components/GoogleReCaptchaV3";

export default {
  components: { Layout, ContactMessage, GoogleReCaptchaV3 },
  data() {
    return {
      window_w: 0,
      loadingForm: false,
      contactErrorData: {},
      contactSuccessData: {},
      GoogleReCaptcha: {
        siteKey: '6Lf5Pb4UAAAAAAozykxHI5OhnnTntOuFhhPR8rn1',
      },
      files: [],
      formContact: {
        name: '',
        email: '',
        phone: '',
        description: '',
        attach_files: [],
        g_recaptcha_response: null,
      },
      rulesContact: {
        name: [
          { required: true, message: '請輸入姓名', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '請輸入詢問內容', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '請輸入電子郵件', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的電子郵件格式', trigger: ['blur'] }
        ],
      },
      text: {
        'zh-TW': {
          content: "請留下相關問題及聯繫方式<br>我們將盡快與您聯繫<br>用餐訂位請前往各品牌官網或是撥打訂位專線洽詢"
        },
        en: {
          content: "Please leave your questions and contact info<br>We will contact you ASAP<br>To make a reservation, please visit each brand’s official website, or call the reservation number"
        }
      }
    }
  },
  computed: {
    ...setComputed,
    textModule() {
      return this.currentLanguage === 'zh-TW' ? this.text['zh-TW'] : this.text['en'];
    },
    sectionTranformStyle() {
      const originWidth = 1920;
      const windthWidth = this.window_w;

      if(this.window_w > 1920) {
        let scale = 1 / (originWidth / windthWidth);

        return {
          transform: `scale(${scale})`,
          '-webkit-transform': `scale(${scale})`,
        }
      }else {
        return {}
      }

    },
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    onSubmit() {
      if(!this.loadingForm) {
        this.$refs['formContact'].validate((valid) => {
            this.contactErrorData = {};
            this.contactSuccessData = {};
            if (valid) {
              this.loadingForm = true;

              let formData = new FormData();

              Object.keys(this.formContact).forEach((key) => {
                formData.append(key, this.formContact[key]);
              });

              for( let i = 0; i < this.files.length; i++ ){
                let file = this.files[i];

                formData.append('attach_files[' + i + ']', file);
              }

              axios.post(`/contactUS`, formData)
                .then((response) => {
                  this.contactSuccessData = response.data;
                  this.$refs['formContact'].resetFields();
                  this.files = [];
                  this.loadingForm = false;
                })
                .catch((error) => {
                  this.contactErrorData = error.response.data;
                })
                .finally( () => {
                    this.$refs.captcha.execute();
                  this.loadingForm = false;
                });
            }
        });
      }
    },
    /* onChangeFile(file, fileList) {
      this.file = fileList
    }, */
    onChangeFile() {
      this.files = this.$refs.files.files;
    },
    /* onChangeFileError(err, file, fileList) {
      console.log(err)
      this.loadingForm = false;
      this.contactErrorData = err.response.data;
    },
    onChangeFileSuccess(response, file, fileList) {
      console.log(response)
      this.contactSuccessData = response.data;
      this.$refs['formContact'].resetFields();
      this.loadingForm = false;
    }, */
    onContactMessageClose() {
      this.contactErrorData = {};
      this.contactSuccessData = {};
    },
    getWindowWidth() {
      this.window_w = document.body.clientWidth
    },
  }
}
</script>
<template>
  <Layout>
    <section :class="$style.sectionContact" class="section">
      <div :class="$style.container" class="h-100">
        <div class="align-items-stretch h-100 row" :style="sectionTranformStyle">
          <div :class="$style.colContact" class="d-flex align-items-center">
            <div :class="$style.textBox">
              <!-- <h2 :class="$style.subtitle">聯繫我們</h2>
              <h1 :class="$style.title">CONTACT US</h1>-->
              <img v-if="currentLanguage === 'zh-TW'" alt="contact us" :class="$style.titleImage" src="@assets/images/contact/word_contact_us.svg">
              <img v-else alt="contact us" :class="$style.titleImage" src="@assets/images/contact/word_contact_us_en.svg">
              <p :class="$style.content" v-html="textModule.content"></p>
              <p :class="$style.phone">{{$t('global.TEL')}}：<a href="tel:+886 2-2711-4636">+886 2-2711-4636</a> (10:00~19:00)</p>
              <GoogleReCaptchaV3
                :id="'contact_us_captcha'"
                ref="captcha"
                v-model="formContact.g_recaptcha_response"
                :action="'contact_us'"
                :site-key="GoogleReCaptcha.siteKey"
                :inline="false"
              ></GoogleReCaptchaV3>
            </div>
          </div>
          <div
            :class="$style.colForm"
            class="align-items-center d-flex"
          >
            <div :class="$style.formBox" class="d-flex align-items-end">
              <el-form
                ref="formContact"
                :class="$style.form"
                :rules="rulesContact"
                :model="formContact"
              >
                <ContactMessage
                  :error-data="contactErrorData"
                  :success-data="contactSuccessData"
                  @close="onContactMessageClose"/>
                <el-form-item :label="$t('global.NAME') + ' *'" prop="name">
                  <el-input v-model="formContact.name" ></el-input>
                </el-form-item>
                <el-form-item :label="$t('global.EMAIL') + ' *'" prop="email">
                  <el-input v-model="formContact.email" ></el-input>
                </el-form-item>
                <el-form-item :label="$t('global.TEL')" prop="phone">
                  <el-input v-model="formContact.phone"></el-input>
                </el-form-item>
                <el-form-item :label="$t('global.CONTENT_OF_INQUIRY') + ' *'" prop="description">
                  <el-input
                    v-model="formContact.description"
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 6 }"
                  ></el-input>
                </el-form-item>
                <div class="d-flex justify-content-between mt-4">
                  <label :class="$style.btnPaperclip">
                    <input ref="files" multiple type="file" class="d-none" @change="onChangeFile"/>
                    <font-awesome-icon
                      :icon="['fal', 'paperclip']"></font-awesome-icon>
                    <span v-if="files.length > 0" class="ml-2 " style="font-size: 14px">({{files.length}})</span>
                  </label>
                  <button :disabled="loadingForm" type="button" :class="$style.btnSubmit" @click="onSubmit">
                    {{$t('global.SUBMIT')}}
                  </button>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  .sectionContact {
    background: #ece8de;
    min-height: 600px;
    height: calc(100vh - 190px);
    overflow: scroll;
    // padding: 5rem 1.5rem;
    @include touch {
      padding: 3rem 20px;
      height: auto;
    }
    .container {
      @include container(1590px);
    }
    .colContact {
      @include col(550px);
    }
    .colForm {
      @include col(1041px);
      max-width: calc(100vw - 525px);
      padding: 33px 15px;
      @include touch {
        padding: 0;
      }
    }
    .textBox {
      padding-left: 1rem;
      padding-top: 60px;
      @include touch {
        padding-left: 0;
        padding-top: 0;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 34px;
      }
    }
    .titleImage {
      margin-bottom: 76px;
      @include touch {
        width: 265px;
        margin-bottom: 40px;
      }
    }
    .content {
      @include font(17px, 28px, 2.55px, 600);
      font-family: 'Noto Serif TC', serif;
      margin-bottom: 60px;
      @include touch {
        @include font(14px, null, 2.1px, null);
        margin-bottom: 40px;
      }
    }
    .phone {
      @include font(15px, 31px, 1.8px, 400);
      font-family: PingFangTC-Regular, Noto Sans TC, sans-serif;
      @include touch {
        @include font(12px, null, 1.44px, null);
      }
      a {
        color: #000;
        &:hover {
          color: #ad9d8b;
          text-decoration: none;
        }
      }
    }
    .formBox {
      background-image: url('@assets/images/contact/contact_bg.png');
      padding: 3rem 6rem;
      width: 100%;
      height: calc((100vh - 263px));
      margin-bottom: 7px;
      min-height: 600px;
      max-height: 817px;
      @include touch {
        padding: 24px 20px;
        height: auto;
      }
      .form {
        width: 446px;
        margin-left: auto;
        position: relative;
      }
      .btnSubmit {
        @include font(15px, 20px, 3px, 400);
        font-family: PingFangTC-Regular, Noto Sans TC, sans-serif;
        appearance: none;
        background: #000000;
        color: #F5F1ED;
        padding: 9px 25px;
        letter-spacing: 3px;
        border: 1px solid #0a0a0a;
        &:hover {
          background: #ad9d8b;
        }
      }
      .upload {
        input {
          display: none;
        }
      }
      .btnPaperclip {
        @include font(23px, null, null, null);
        appearance: none;
        border: 1px solid #0a0a0a;
        color: #0a0a0a;
        background: transparent;
        height: 40px;
        width: auto;
        min-width: 38px;
        padding: 0 7.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        &:hover {
          color: #ad9d8b;
          border-color: #ad9d8b;
        }
      }
    }
  }
</style>
