<script>
import { setComputed } from '@state/helpers'

export default {
  props: {
    errorData: {
      type: Object,
      required: true
    },
    successData: {
      type: Object,
      required: true
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...setComputed,
  },
};
</script>
<template>
  <div>
    <transition name="zoom">
      <div v-if="Object.keys(errorData).length > 0 || Object.keys(successData).length > 0"
           :class="[$style.messageBox, { [$style.isError]: Object.keys(errorData).length > 0, [$style.isSuccess]: Object.keys(successData).length > 0 }]">
        <h6 class="mb-0">
          <template v-if="Object.keys(errorData).length > 0">
            <font-awesome-icon icon="exclamation-circle" class="mr-2"></font-awesome-icon>
            <template v-if="errorData.status">
              <img src="@assets/images/icon/error.png" style="position: relative; top: -2px;">
              {{$t(`response.RESPONSE_${errorData.status.toUpperCase()}`)}}
            </template>
            <template v-else>
              <img src="@assets/images/icon/error.png" style="position: relative; top: -2px;"> {{$t('response.RESPONSE_UNDEFINED')}}
            </template>
          </template>
          <template v-if="Object.keys(successData).length > 0">
            <!--<font-awesome-icon icon="envelope-open-text" class="mr-2"></font-awesome-icon>-->
            <img src="@assets/images/icon/success.png" style="position: relative; top: -2px;"> {{$t('response.RESPONSE_SEND_SUCCESS')}}
          </template>
        </h6>
        <!-- <div class="pt-3" v-if="errorData.status === 'INPUT_INVALID'">
          <div v-for="(item, $key) in errorData.data" :key="$key">
            <span>{{$t(`response.FIELD_${$key.toUpperCase()}`)}}</span>: <span>{{item[0]}}&lt;!&ndash;{{$t(`response.RESPONSE_${item[0]
            .toUpperCase()}`)}}&ndash;&gt;</span>
          </div>
        </div> -->
        <!-- <font-awesome-icon
          @click="onClose"
          :class="$style.closeBtn"
          :icon="['fal','times']"
        >
        </font-awesome-icon> -->
      </div>
    </transition>
  </div>
</template>
<style lang="scss" module>
  .messageBox {
    position: absolute;
    bottom: 0;
    font-size: 0.9rem;
    color: #000;
    padding: 10.5px 20px;
    background: #f5f1ed;
    position: absolute;
    width: 255px;
    right: 138px;
    z-index: 1;
    @include touch {
      position: fixed;
      top: 70px;
      width: 100%;
      left: 0;
      box-shadow: 0px 0px 10px #0000001f;
      bottom: auto;
    }
    &.isError {
      color: #932a2a;
    }
    &.isSuccess {
      color: #508661
    }
    .closeBtn {
      position: absolute;
      top: 18px;
      right: 19px;
    }
  }

</style>
